@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


/* src/index.css or src/App.css */

/* Apply the Poppins font globally */
body {
    font-family: 'Poppins', sans-serif;
    /* Set Poppins as the primary font */
    font-size: 16px;
    /* Set font size to 16px */
    font-weight: 400;
    /* Regular font weight */
    line-height: 1.75;
    /* Set line height for readability */
    letter-spacing: 0px;
    /* Set letter spacing */
    color: #4d6995;
    /* Custom text color */
    text-transform: none;
    /* Disable text transformation */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Ensure consistent box model */
}

p {
    font-family: 'Poppins', sans-serif;
    /* Set Poppins as the primary font */
    font-size: 16px;
    /* Set font size to 16px */
    font-weight: 400;
    /* Regular font weight */
    line-height: 1.75;
    /* Set line height for readability */
    letter-spacing: 0px;
    /* Set letter spacing */
    color: #4d6995;
    /* Custom text color */
    text-transform: none;
    /* Disable text transformation */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Ensure consistent box model */
}

.vasd_title_header {
    max-width: 100%;
    color: #002866;
    font-family: "Poppins", Sans-serif;
    font-size: 53px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 53px;
    padding: 25px 30px 25px 30px;
}
.vasd_banner_title {
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #ffffff;
    /* color: #002866; */
    text-transform: none;
}
.vasd_h1 {
    font-family: "Poppins";
    font-size: 42px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.vasd_h2 {
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.vasd_h3 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.vasd_h4 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.vasd_h5 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.vasd_h6 {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.primary-navigation ul a,
.primary-navigation ul.primary-menu a {

    font-family: "Poppins";
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}

.primary-navigation ul ul.sub-menu a,
.primary-navigation ul.primary-menu ul.sub-menu a {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #002866;
    text-transform: none;
}


.page-titlebar .page-title {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 700;
    line-height: 1.25;
    color: #ffffff;
    text-transform: uppercase;
}

.page-titlebar .breadcrumbs {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #002866;
    text-transform: uppercase;
}

.page-titlebar .breadcrumbs,
.page-titlebar .page-desc {

    background-color: #FFEE00;
}

.page-titlebar {
    background-color: #f0f0f1;
    /* //background-image: url(https://alonethemes.com/wp-content/uploads/2021/05/ab-bg-page-title.jpg); */
    background-image: url('./assets/images/pagetitle.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 75px;
    padding-bottom: 75px;
}



.home_statics {
    background-image: url("https://staging-beplusthemes.kinsta.cloud/wp-content/uploads/2020/10/pmr-bg-help.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: auto;
    
    
}

.text-color-yellow {

    color: #FFEE00;
}

.text-color-blue {
 color: #002866;
}

.footerbg {
    background-color: #002866;
}

.footerbg_title {
    color: #FFEE00;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
}


.image-container {
    /* width: 300px; Set the container width */
    /* height: 200px; Set the container height */

    position: relative;
    /* Ensures proper positioning for child elements */
}

.image-container img {

    /* Makes the image responsive */

    /* Ensures the image covers the container */
    object-fit: cover;
    /* Ensures the image retains its aspect ratio */
    transition: transform 0.3s ease-in-out;
    /* Smooth hover effect */
}

.image-container:hover img {
    transform: scale(1.05);
    /* Zooms the image on hover */
}


.navbar-toggler {
    background-color: #4D6995; /* Background color */
    border: none; /* Remove border if not needed */
  }
  
  .navbar-toggler-icon {
    background-image: none; /* Remove default icon styling */
    color: #FFEE00; /* Icon color */
    font-size: 1.5rem; /* Adjust size if necessary */
  }
  
  /* Optional: Add a custom icon */
  .navbar-toggler-icon::before {
    content: '☰'; /* Unicode character for hamburger icon */
    display: block;
    text-align: center;
  }
  