.banner-bg {
  /* background-image: url(https://staging-beplusthemes.kinsta.cloud/wp-content/uploads/2020/11/pmr-bg-slide.jpg); */
  background-image: url('../assets/images/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  color: #4D6995;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.banner_h1 {
  font-family: "Poppins";
  max-width: 100%;
  color: #002866;
  font-family: "Poppins", Sans-serif;
  font-size: 53px;
  font-weight: 700;
  text-transform: uppercase;
}


/* Custom Primary Button Styles */
.btn-contact {
  font-family: 'Poppins', sans-serif;
  /* Use Poppins font */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition for hover effect */
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  fill: #002866;
  color: #002866;
  background-color: #FFEE00;
  padding: 20px 40px 20px 40px;
  text-decoration: none;

}

/* Hover Effect */
.btn-contact:hover {
  background-color: #002866;
  /* Set background to yellow on hover */
  color: #FBEA00;
  /* Set text color to dark blue on hover */
  border-color: #FBEA00;
  /* Optional: match border to background on hover */
}

/* Focus and Active states (optional) */
.btn-contact :focus,
.btn-contact :active {
  outline: none;
  /* Remove focus outline */
  box-shadow: none;
  /* Remove any box-shadow */

}



/* Custom Primary Button Styles */
.btn-donations {
  font-family: 'Poppins', sans-serif;
  /* Use Poppins font */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition for hover effect */
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  fill: #FFEE00;
  color: #002866;
  background-color: #ffffff;
  border-width: 4px;
  border-color: #002866;
  padding: 16px 40px 16px 40px;
  text-decoration: none;

}

/* Hover Effect */
.btn-donations:hover {
  background-color: #FBEA00;
  /* Set background to yellow on hover */
  color: #002866;
  /* Set text color to dark blue on hover */
  border-width: 4px;
  border-color: #FBEA00;
  box-shadow: none;
  /* Optional: match border to background on hover */
}

/* Focus and Active states (optional) */
.btn-donations :focus,
.btn-donations :active {
  outline: none;
  /* Remove focus outline */
  box-shadow: none;
  /* Remove any box-shadow */

}