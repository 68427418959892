/* Style for Card Image Zoom on Hover */
.card-img-top {
  transition: transform 0.3s ease;
  /* Smooth zoom effect */
  overflow: hidden;
}

.card:hover .card-img-top {
  transform: scale(1.1);
  overflow: hidden;
  /* Scale up the image on hover */
}

/* Title overlay on image */
.card-body {
  position: relative;
}

