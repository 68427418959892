/* Custom styles for navbar links */

.vasd-navbar .nav-item {
    /* padding: 0px 10px 10px 10px; */
   
}


.vasd-navbar .nav-link {
    font-family: 'Poppins', sans-serif;
    /* Use Poppins font */
    font-size: 14px;
    /* Set font size */
    font-weight: 700;
    /* Set font weight to bold */
    letter-spacing: 0;
    /* Set letter spacing */
    text-transform: uppercase;
    /* Convert text to uppercase */
    color: #002866;
    /* Set text color to yellow */
    /* Adjust padding inside the link */
    border: 1px solid transparent;
    /* Remove border */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Smooth transition for hover effect */
    line-height: 20px;
    /* Set line height */
    text-decoration: none;
    /* Remove underline */
    padding-left: 20px !important;  /* Set left padding */
    padding-right: 20px !important; /* Set right padding */
    padding-top: 20px;
    padding-bottom: 20px;

    width: 100%;
  text-align: center; /* Center the text */
  display: block; /* Make the link a block element to fill the nav item */


}

/* Hover effect for nav links */
.vasd-navbar .nav-link:hover {
    background-color: #FBEA00;
    color: #002866;
    border-color: #FBEA00;
}

/* Focus and Active states for links (optional) */
.vasd-navbar .nav-link:focus {
    background-color: #FBEA00;
    color: #002866;
 }

.vasd-navbar .nav-link.active {
    background-color: #FBEA00;
    /* Set background to yellow on hover */
    color: #002866 !important;

}

.dropdown-menu {
    font-family: 'Poppins', sans-serif;
    background-color: #FBEA00; /* Green background */
    border-radius: 0.375rem;
    font-size: 14px;
    font-weight: 700;
  }

  /* Change color of the links inside the dropdown */
  .dropdown-menu a.dropdown-item {
    color: #002866 !important;/* White text */
  }

  /* Change color on hover */
  .dropdown-menu a.dropdown-item:hover {
    background-color: #f3f0c8; /* Darker green on hover */
  }



/* Change the background color of the active nav link to red */
 

/* Custom Primary Button Styles */
.btn-donation {
    font-family: 'Poppins', sans-serif;
    /* Use Poppins font */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Smooth transition for hover effect */
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    fill: #FFEE00;
    color: #FFEE00;
    background-color: #002866;
    padding: 25px 30px 25px 30px;
    text-decoration: none;
   
}

/* Hover Effect */
.btn-donation:hover {
    background-color: #FBEA00;
    /* Set background to yellow on hover */
    color: #002866;
    /* Set text color to dark blue on hover */
    border-color: #FBEA00;
    /* Optional: match border to background on hover */
}

/* Focus and Active states (optional) */
.btn-donation:focus,
.btn-donation:active {
    outline: none;
    /* Remove focus outline */
    box-shadow: none;
    /* Remove any box-shadow */

}